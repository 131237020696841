'use client';
import type { PlatformApiEndpoints } from '@carbonfact/shared/src/types/platform';
import { ReleaseMetric } from '@carbonfact/shared/src/types/platform/releases';
import getLcaStageColor, {
  DATA_COLOR_SCALE_SCHEME,
} from '@carbonfact/shared/src/ui/datavizColors';
import { Tag } from '@carbonfact/ui-components/src/Tag';
import * as Plot from '@observablehq/plot';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import ClickableDropdown from 'app/components/ClickableDropdown';
import Icon from 'app/components/Icon';
import { Table } from 'app/components/Table';
import formatNumber, { formatPercentDifference } from 'app/lib/formatNumber';
import useEndpoint from 'app/lib/useEndpoint';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import { useTranslations } from 'next-intl';
import { useEffect, useMemo, useRef } from 'react';

import { displayReleaseMetricValue } from '../utils';

type ReleaseMetricRow =
  PlatformApiEndpoints['/release/latest/metrics']['response']['metrics'][number];

const columnHelper = createColumnHelper<ReleaseMetricRow>();

export default function ReleaseMetricsTable() {
  const { data, isLoading } = useEndpoint('/release/latest/metrics');
  const t = useTranslations();

  const sortedMetrics = useMemo(
    // Show the metrics ordered by category
    () =>
      data?.metrics
        ? sortBy(
            data.metrics,
            (item) => categorySortIndex[metricsCategoryMap[item.metric]],
          )
        : [],
    [data?.metrics],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => metricsCategoryMap[row.metric], {
        header: 'Category',
        cell: (cellContext) => (
          <div className="flex flex-row items-start">
            <Tag className="text-gray-700 bg-gray-100 gap-1">
              <div
                className="w-3 h-3 rounded-[4px]"
                style={{
                  backgroundColor: categoryColors[cellContext.getValue()],
                }}
              />

              {t(`homeMetrics.categories.${cellContext.getValue()}`)}
            </Tag>
          </div>
        ),
      }),

      columnHelper.accessor('metric', {
        header: 'Metric',
        size: 400, // Fill as much space as possible
        cell: (cellContext) => (
          <ClickableDropdown
            disclosure={
              <div className="flex flex-row gap-1 opacity-70 hover:opacity-100 transition-opacity duration-200 cursor-pointer">
                <Icon
                  height={20}
                  width={20}
                  icon={{ source: 'local', name: 'info-cr-fr', type: 'solid' }}
                />
                <span className="text-black text-sm font-medium">
                  {t(`homeMetrics.byRelease.${cellContext.getValue()}`)}
                </span>
              </div>
            }
          >
            <div className="bg-carbon-800 text-white py-2 px-4">
              <p className="text-sm">
                {t(
                  `homeMetrics.byRelease.descriptions.${cellContext.getValue()}`,
                )}
              </p>
            </div>
          </ClickableDropdown>
        ),
      }),

      columnHelper.accessor('value', {
        id: 'latest-value',
        header: () => (
          <div className="min-w-fit whitespace-nowrap">
            {t('ReleaseMetricsTable.version')} {data?.version ?? 'X'}
          </div>
        ),
        cell: (info) =>
          info.getValue() === null
            ? '—'
            : displayReleaseMetricValue(
                info.row.original.metric,
                info.getValue() as number,
              ),
        meta: {
          className: 'text-right justify-end',
        },
      }),

      columnHelper.display({
        id: 'change',
        header: () => (
          <div className="min-w-fit whitespace-nowrap">
            {t('ReleaseMetricsTable.header.change')}{' '}
            {t('ReleaseMetricsTable.header.sinceVersion', {
              version: data?.version ? data.version - 1 : 'X-1',
            })}
          </div>
        ),
        cell: (cellContext) => {
          const row = cellContext.row.original;

          const lastValue = row.value;
          const previousValue = row.valueHistory.at(-1) || null;

          let evolution = 0;
          let displayed: string;

          if (lastValue === null || previousValue === null) {
            displayed = '—';
          } else {
            evolution = (lastValue - previousValue) / previousValue;

            if (Math.abs(evolution) < 0.01) {
              // Dont bother highlighting unsignificant changes
              displayed = 'stable';
              evolution = 0;
            } else if (
              [
                ReleaseMetric.TOTAL_FOOTPRINT,
                ReleaseMetric.PRODUCT_FOOTPRINT,
                ReleaseMetric.UNITS,
                ReleaseMetric.PRODUCT_COUNT,
              ].includes(row.metric)
            ) {
              // Count metrics, show percentage evolution
              displayed = formatPercentDifference(evolution);
            } else {
              // Rate metrics, show percentage points evolution
              displayed = `${evolution > 0 ? '+' : ''}${formatNumber(
                (lastValue - previousValue) * 100,
              )}pp`;
            }
          }

          const changeIsPositive: boolean | undefined =
            mamaAreWeGoingInTheRightDirection(row.metric, evolution);

          return (
            <div
              className={classNames(
                changeIsPositive !== undefined && 'font-medium',
                changeIsPositive === true && 'text-green-700',
                changeIsPositive === false && 'text-red-700',
              )}
            >
              {displayed}
            </div>
          );
        },
        meta: {
          className: 'text-right justify-end relative',
        },
      }),

      columnHelper.display({
        header: 'Evolution',
        cell: (props) => ReleaseMetricEvolutionGraph({ cellContext: props }),
        size: 1,
        meta: {
          className: 'relative justify-end text-right',
        },
      }),
    ],
    [t, data?.version],
  );

  return (
    <section className="w-full">
      <h2 className="text-carbon-800 text-base font-medium">
        {t('ReleaseMetricsTable.metrics')}
      </h2>
      <h3 className="text-carbon-500 text-xs font-medium mb-3">
        {t('ReleaseMetricsTable.measurementsChangesAcrossVersions')}
      </h3>

      <div className="rounded-xl border-[1.5px] border-carbon-200 bg-white p-4 flex flex-col gap-2">
        <Table.Default
          isLoading={isLoading}
          columns={columns}
          data={sortedMetrics ?? []}
          hasPagination={false}
        />
      </div>
    </section>
  );
}

enum ReleaseMetricCategory {
  Output = 'output',
  Coverage = 'coverage',
  Traceability = 'traceability',
  RawMaterials = 'rawMaterials',
  Processing = 'processing',
}

const categoryOrder: ReleaseMetricCategory[] = [
  ReleaseMetricCategory.Output,
  ReleaseMetricCategory.Coverage,
  ReleaseMetricCategory.Traceability,
  ReleaseMetricCategory.RawMaterials,
  ReleaseMetricCategory.Processing,
];

// Create a mapping of category to its sort order index
const categorySortIndex = categoryOrder.reduce<
  Partial<Record<ReleaseMetricCategory, number>>
>((acc, category, index) => {
  acc[category] = index;
  return acc;
}, {});

const metricsCategoryMap: Record<ReleaseMetric, ReleaseMetricCategory> = {
  [ReleaseMetric.TOTAL_FOOTPRINT]: ReleaseMetricCategory.Output,
  [ReleaseMetric.PRODUCT_FOOTPRINT]: ReleaseMetricCategory.Output,
  [ReleaseMetric.UNITS]: ReleaseMetricCategory.Output,
  [ReleaseMetric.PRODUCT_COUNT]: ReleaseMetricCategory.Output,
  [ReleaseMetric.RELATIVE_UNCERTAINTY]: ReleaseMetricCategory.Output,
  [ReleaseMetric.COLLECTION_RATE]: ReleaseMetricCategory.Coverage,
  [ReleaseMetric.COMPLETION_RATE]: ReleaseMetricCategory.Coverage,
  [ReleaseMetric.MEASURED_RATE]: ReleaseMetricCategory.Coverage,
  [ReleaseMetric.COVERAGE_RATE]: ReleaseMetricCategory.Coverage,
  [ReleaseMetric.NAMED_MATERIALS_SHARE]: ReleaseMetricCategory.Coverage,
  [ReleaseMetric.KNOWN_LOCATION]: ReleaseMetricCategory.Traceability,
  [ReleaseMetric.KNOWN_SUPPLIER]: ReleaseMetricCategory.Traceability,
  [ReleaseMetric.KNOWN_LOSS_RATE]: ReleaseMetricCategory.Traceability,
  [ReleaseMetric.KNOWN_PRODUCT_WEIGHT]: ReleaseMetricCategory.Traceability,
  [ReleaseMetric.KNOWN_COMPONENT_WEIGHT]: ReleaseMetricCategory.Traceability,
  [ReleaseMetric.UNKNOWN_RAW_MATERIALS]: ReleaseMetricCategory.RawMaterials,
  [ReleaseMetric.KNOWN_TEXTILE_FORMATION_DTEX]:
    ReleaseMetricCategory.Processing,
  [ReleaseMetric.KNOWN_TEXTILE_FORMATION_GSM]: ReleaseMetricCategory.Processing,
  [ReleaseMetric.KNOWN_TEXTILE_FORMATION_METHOD]:
    ReleaseMetricCategory.Processing,
};

const categoryColors: Record<ReleaseMetricCategory, string> = {
  [ReleaseMetricCategory.Output]: DATA_COLOR_SCALE_SCHEME[9],
  [ReleaseMetricCategory.Coverage]: DATA_COLOR_SCALE_SCHEME[3],
  [ReleaseMetricCategory.Traceability]: DATA_COLOR_SCALE_SCHEME[5],
  [ReleaseMetricCategory.RawMaterials]: getLcaStageColor('rawMaterials'),
  [ReleaseMetricCategory.Processing]: getLcaStageColor('processing'),
};

// Fill the cell! works because we've set it to position: relative.
const fillContainingCellClassNames =
  'absolute inset-2 flex items-center justify-end';

function mamaAreWeGoingInTheRightDirection(
  metric: ReleaseMetric,
  evolution: number,
): boolean | undefined {
  if (evolution === 0) return; // not moving at all

  switch (metric) {
    case ReleaseMetric.TOTAL_FOOTPRINT:
    case ReleaseMetric.PRODUCT_FOOTPRINT:
    case ReleaseMetric.RELATIVE_UNCERTAINTY:
    case ReleaseMetric.UNKNOWN_RAW_MATERIALS:
      // Less is better
      return evolution < 0;
    default:
      // More is better
      return evolution > 0;
  }
}

function ReleaseMetricEvolutionGraph({
  cellContext,
}: {
  cellContext: CellContext<ReleaseMetricRow, unknown>;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const row = cellContext.row.original;

    // Always show 6 data points
    const data: { x: number; y: number | null }[] = [
      ...new Array<null>(6).fill(null),
      ...row.valueHistory,
      row.value,
    ]
      .slice(-6)
      .map((value, index) => ({ x: index, y: value }))
      .filter((d) => d.y !== null);

    const smallestValue =
      data.length > 0
        ? Math.min(
            ...data
              .map((d) => d.y)
              .filter((y): y is number => typeof y === 'number'),
          )
        : 0;
    const noHistoricalData = data.length <= 1;

    const plot = Plot.plot({
      width: 100,
      height: 50,
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 12,
      marginRight: 12,
      // Disable default axes
      x: {
        axis: null,
        label: null,
        // Always show 6 values max
        domain: [0, 5],
      },
      y: {
        axis: null,
        label: null,
        // default scale to 0-1 so we can draw our X-axis if there's no data
        domain: noHistoricalData ? [0, 1] : undefined,
      },
      marks: [
        // X-axis
        Plot.ruleY(
          [
            !noHistoricalData
              ? // Draw at the smallest Y value...
                smallestValue -
                // With a 15% bottom margin
                0.15 * smallestValue
              : 0, // or draw at just above 0 if there's no data
          ],
          {
            stroke: '#DDDEDE',
            strokeWidth: 1.5,
          },
        ),
        // X-axis ticks
        Plot.axisX({
          anchor: 'bottom',
          ticks: data.length,
          tickFormat: () => '',
          stroke: '#DDDEDE',
          tickSize: 6,
          dy: -3,
          strokeWidth: 1.5,
        }),
        // Highlighted line and point going to the last value
        Plot.lineY(data.slice(-2), {
          x: 'x',
          y: 'y',
          stroke: '#3C4147',
          strokeWidth: 1,
        }),
        // Highlighted dot for the last value
        Plot.dot(data.slice(-1), {
          x: 'x',
          y: 'y',
          fill: '#3C4147',
          r: 3,
        }),
        Plot.lineY(data.slice(0, -1), {
          x: 'x',
          y: 'y',
          stroke: '#ACB2B9',
          strokeWidth: 1,
        }),
        // Historical data
        Plot.dot(data.slice(0, -1), {
          x: 'x',
          y: 'y',
          fill: '#ACB2B9',
          r: 3,
        }),
      ],
    });

    containerRef.current?.append(plot);
    return () => plot.remove();
  }, [cellContext]);

  return <div ref={containerRef} className={fillContainingCellClassNames} />;
}
